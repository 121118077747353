if (typeof String.prototype.trim !== 'function') {
    String.prototype.trim = function () {
        return this.replace(/^\s+|\s+$/g, '');
    }
}

if (typeof Array.prototype.indexOf !== 'function') {
    Array.prototype.indexOf = function (val) {
        for (var i = 0; i < this.length; i++) {
            if (this[i] == val) {
                return i;
            }
        }
        return -1;
    }
}

if (!Array.prototype.forEach) {
    Array.prototype.forEach = function (fun /*, thisp*/) {
        var len = this.length;
        if (typeof fun != "function")
            throw new TypeError();

        var thisp = arguments[1];
        for (var i = 0; i < len; i++) {
            if (i in this)
                fun.call(thisp, this[i], i, this);
        }
    };
}

if (!Array.prototype.isArray) {
    Array.isArray = function (obj) {
        return Object.prototype.toString.call(obj) === "[object Array]";
    };
}

// Cross-domain request fix for IE
if (window.XDomainRequest) {
    jQuery.ajaxTransport(function (s) {
        if (s.crossDomain && s.async) {
            if (s.timeout) {
                s.xdrTimeout = s.timeout;
                delete s.timeout;
            }
            var xdr;
            return {
                send: function (_, complete) {
                    function callback(status, statusText, responses, responseHeaders) {
                        xdr.onload = xdr.onerror = xdr.ontimeout = jQuery.noop;
                        xdr = undefined;
                        complete(status, statusText, responses, responseHeaders);
                    }
                    xdr = new XDomainRequest();
                    xdr.onload = function () {
                        callback(200, "OK", { text: xdr.responseText }, "Content-Type: " + xdr.contentType);
                    };
                    xdr.onerror = function () {
                        callback(404, "Not Found");
                    };
                    xdr.onprogress = jQuery.noop;
                    xdr.ontimeout = function () {
                        callback(0, "timeout");
                    };
                    xdr.timeout = s.xdrTimeout || Number.MAX_VALUE;
                    xdr.open(s.type, s.url);
                    xdr.send((s.hasContent && s.data) || null);
                },
                abort: function () {
                    if (xdr) {
                        xdr.onerror = jQuery.noop;
                        xdr.abort();
                    }
                }
            };
        }
    });
}

//IE doesnt define the console object unless the dev tools are open - create a placeholder object to avoid script errors while debugging issues.
if (typeof window.console == "undefined") {
    console = { log: function () { } };
}

$(function () {
    //modify our API Authentication setup
    WCC.API.apiRequestSetAuth = function (everything) {
        //this is IE, no headers are available and we can't use the body or we risk breaking the ModelBinding - have to use the querystring

        //do we start with ? or &
        if (everything.url.indexOf('?') == -1) {
            everything.url += '?';
        }
        else {
            everything.url += '&';
        }
        
        everything.url += "auth=" + WCC.API.token;
        if (WCC.API.loginToken) {
            everything.url += "&auth-login=" + WCC.API.loginToken;
        }
        if (WCC.API.dns) {
            everything.url += "&dns=" + WCC.API.dns;
        }
    };
});