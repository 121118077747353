require('Scripts/WCC/CommonScripts/WCCAutoGrowHook');
require('Scripts/WCC/WCCSettings');

require('libraries/bootstrap');
require('libraries/icheck');
require('libraries/glyphicons');
require('libraries/fontAwesome');
require('libraries/fancybox');

require('jquery-reveal');

if (settings.isIE) {
    require("Scripts/WCC/WCCIEFix")
    require("Scripts/ie.shiv")
}

if (settings.isBrochure) {
    require('Content/WCC/main.scss');
    require('Content/WCC/loggedUser.scss');
    require('Content/Shared/sharedStyles.scss');
} else {
    require('libraries/messenger');

    require('Content/WCC/main.scss');
    require('Content/Shared/text.scss');
    require('Content/animate.css');
    

    if (settings.isLoggedIn) {
        require('Content/WCC/loggedUser.scss');
        require('Content/Shared/sharedStyles.scss');        
    }
}