WCC.AutoGrowHook = new (WCC.extend(WCC.emptyFn, {

    initMirror: function (textAreaObj) {
        var mirror = textAreaObj.next('.autogrow-textarea-mirror');

        if (mirror.length == 0) return;

        if (!mirror.length || !mirror.hasClass('autogrow-textarea-mirror')) {
            textAreaObj.after('<div class="autogrow-textarea-mirror"></div>');
            mirror = textAreaObj.next('.autogrow-textarea-mirror');
        }

        // Style the mirror
        mirror.css('display', 'none');
        mirror.css('wordWrap', 'break-word');
        mirror.css('whiteSpace', 'normal');
        mirror.css('padding', textAreaObj.css('padding'));
        mirror.css('width', textAreaObj.css('width'));
        mirror.css('fontFamily', textAreaObj.css('font-family'));
        mirror.css('fontSize', textAreaObj.css('font-size'));
        mirror.css('lineHeight', textAreaObj.css('line-height'));

        // Style the textarea
        textAreaObj.css('overflow', "hidden");
    },

    sendContentToMirror: function (textAreaObj) {
        var mirror = textAreaObj.next('.autogrow-textarea-mirror');

        if (mirror.length > 0) {
            mirror[0].innerHTML = String(textAreaObj.val()).replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/'/g, '&#39;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\n/g, '<br />') + ".";

            mirror.css('width', textAreaObj.css('width'));

            if (textAreaObj.height() != mirror.height()) {
                textAreaObj.height(mirror.height());
            }
        }
    }
}))();