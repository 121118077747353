interface TimeZoneInfo {
    timezoneName: string
}

let instance: TimeZoneInfo | undefined;

export function getTimeZoneInfo() {
    if (instance == undefined) {
        var matches = /\((.*)\)/.exec(new Date().toString());

        if (matches && matches.length > 1)
            instance = {
                timezoneName: matches[1]
            }
    }

    return instance;
}